<template>
  <div class="location-container">
    <h1 id="location">DIE LAGE</h1>
    <div class="title-separator-container">
      <div class="title-separator"/>
    </div>
    <div class="location-description-container">
      <div class="location-description">
        Michaelbeuern ist DIE unentdeckte Perle der Ruhe in unmittelbarer Nähe (24 km) zur Stadt Salzburg. <br/><br/>

        In direkter Nachbarschaft befinden sich die Oberinnviertler Seenplatte (Holzösterer See, Höllerer See, und
        Ibmer See, bzw. das Ibmer Moor zum Wandern) im Nordwesten, sowie die Salzburger Hausseen (Grabensee, Mattsee,
        Obertrumer See) im Südosten. <br/><br/>

        Der traumhafte Neubau ist eingebettet in eine bewaldete, hügelige Landschaft und ermöglicht einen spektakulären
        Ausblick in die im Süden gelegenen Salzburger und Berchtesgadener Alpen. <br/><br/>

        In Michaelbeuern erlebt man das <b>Wohnen abseits von Hektik</b>, Touristenströmen und Verkehrslärm, und doch
        bietet sich eine gut ausgebaute Infrastruktur an.

        In Gehweite befindet sich das Ortszentrum, sowie die <b>Benediktinerabtei</b> Michaelbeuern. <br/>
        Die <b>Stiftskellnerei</b> Michaelbeuern ist bekannt für die exquisite Küche. Im Schanigarten unter den alten
        Kastanienbäumen hat man einen traumhaft schönen Blick auf die alten Häuser Michaelbeuerns. <br/> <br/>

        Kindergarten, Volksschule, sowie eine private Neue Mittelschule sind direkt im kleinen Ort vorhanden. Der
        nächste große Supermarkt befindet sich nur einen Kilometer entfernt. Für die kleineren Einkäufe des Alltags
        eignet sich die Bäckerei sowie eine Trafik im Ortszentrum. Ein gut ausgebautes Verkehrsnetz (Straße und Bus)
        ermöglicht die Verbindung zur 24 km südlich gelegenen Stadt Salzburg (25 Minuten mit dem Auto),
        Bus im Stundentakt.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Location"
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/layout.scss";
.location-container {
  padding-top: 60px;
  margin-left: 185px;
  margin-right: 185px;
  text-align: center;
  @include MQ(L) {
    margin-left: 135px;
    margin-right: 135px;
  }
  @include MQ(M) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @include MQ(S) {
    margin-left: 25px;
    margin-right: 25px;
  }
  @include MQ(XS) {
    padding-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
  h1 {
    font-size: 3em;
    @include MQ(XS) {
      font-size: 2em;
    }
  }
  .title-separator-container {
    display: flex;
    justify-content: center;
    .title-separator {
      width: 150px;
      height: 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .location-description-container {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    @include MQ(XS) {
      font-size: 1em;
    }
    .location-description {
      max-width: 1200px;

    }
  }
}
</style>
